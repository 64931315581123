@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: 'proxima-nova', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'proxima-nova', sans-serif;
}


.all-page {
  margin: auto;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .all-page {
    width: 100%;
  }
}
.App {
    
}

.pages {
    opacity: 0.5;
}
.navbar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 120px;
    border-radius: 0em 0em 1em 1em;
    position: fixed;
    z-index: 1;
    width: 100%;
    box-shadow: 5px -1px 12px -5px grey;
}

.navbar-container > .nav-icon {
    display: none;
}

.fade-in-image { 
    -webkit-animation: fadeIn 2s; 
            animation: fadeIn 2s;
}

.navbar-container > .nav-icon-black {
    display: none;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.nav-active {
    background-color: white;
    transition: all 1s;
}

.navbar-container > .nav-logo {
    width: 15%;
    height: 100%;
    z-index: 1;
}

.navbar-container > .nav-logo > img {
    width: 100%;
    height: 100%;
}

.navbar-container > .navbar-items {
    display: flex;
    z-index: 1;
}

.navbar-container > .navbar-items > .navbar-item {
    margin: 0em 2em;
}

#contact-link {
    background-color: #04A953;
    border-radius: 0.3em;
    color: white !important;
    padding: 0.8em 2em;
}

#contact-link:active {
    background-color: #288140;
}

#contact-link:hover::before {
    width: 0%;
}

.navbar-container > .navbar-items > .navbar-item > .navbar-links {
    color: white;
    font-size: 1vw;
    text-decoration: none;
    position: relative;
    font-family: 'Roboto', sans-serif;
    padding: 0.5em 0em;
}

.navbar-container > .navbar-items > .navbar-item > .navbar-links:before {
    content: "";
    transition: all 0.5s;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0%;
    background: transparent;
}

.show-items {
    z-index: 1;
}

.hide-items {
    z-index: 0;
}

.navbar-container > .navbar-items > .navbar-item > .navbar-links:before:active {
    background-color: #288140;
}

.navbar-container > .navbar-items > .navbar-item > .navbar-links:hover:before {
    width: 100%;
    background: #04A953;
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@media screen and (max-width: 960px) {

    .hide-navbar-items {
        display: none !important;
    }

    .navbar-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .background-white {
        background-color: white;
    }

    .show-navbar {
        display: flex;
    }

    .show-index {
        z-index: 1;
    }

    .hide-navbar {
        display: none;
    }

    .hide-navbar-container {
        height: 100px;
    }

    .navbar-container > .navbar-items > .navbar-item > .navbar-links {
        font-size: 16px;
        color: black !important;
    }

    .center-logo-mobile {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto; 
        margin-right: auto; 
    }


    .navbar-container > .navbar-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        opacity: 0;
        transition: all 0.5s ease;
        z-index: -1;
        margin-bottom: 20em;
    }

    .navbar-container > .nav-logo {
        width: unset;
    }

    .navbar-container > .nav-logo > img {
        width: 120px;
        margin-top: 0.5em;
        height: 50px;
    }

    .navbar-container > .navbar-items.active {
        transition: all 0.5s ease;
        opacity: 1;
    }

    .navbar-container > .navbar-items > .navbar-item {
        margin: 1em 1em;
    }

    .navbar-container > .nav-icon {
        display: block;
        position: absolute;
        top: 50px;
        left: 90%;
        color: white;
        cursor: pointer;
        z-index: 1;
    }

    .navbar-container > .nav-icon-black {
        display: block;
        position: absolute;
        top: 50px;
        left: 90%;
        color: black;
        cursor: pointer;
        z-index: 1;
    }
}
.image-container {
    background-image: url(/static/media/image_banner.d6d0a952.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 50vw;
    position: absolute;
    top: 0;
    z-index: 0;
}

.container-sustainable-solutions {
    width: 100%;
}

.flex-display {
    display: flex;
}

.space-items-between {
    justify-content: space-between;
}

.about-us {
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 4em;
    width: 75%;
}

.about-us img {
    width: 30vw;
    margin-right: 10em;
}

.about-us-content .title {
    font-size: 3vw;
    color: #434444;
    font-weight: bold;
}

.about-us-content .subtitle {
    font-size: 2vw;
    color: #434444;
    font-weight: bold;
    margin: 0.5em 0em;
}

.about-us-content .description-title {
    font-size: 1vw;
    color: #555555;
    margin: 1em 0em;
}

.about-us-content .description-subtitle {
    font-size: 1vw;
    color: #555555;
    margin-bottom: 3em;
}

.about-us-content {
    display: flex;
    flex-direction: column;
}

.flex-display-column {
    flex-direction: column;
}

#image-neutral-agriculture {
    width: 50%;
    height: 80%;
}

.half-width {
    width: 50%;
}

.carbon-card img {
    width: 20%;
}

.container-text-neutral-agriculture > .title {
    font-size: 3vw;
    color: #707070;
    width: 90%;
}

.container-text-neutral-agriculture .subtitle {
    font-size: 1.5vw;
    color: #555555;
    font-weight: bold;
}

.container-text-neutral-agriculture .text {
    font-size: 1vw;
    color: #555555;
    width: 80%;
}

.image-marca-sobrenos > img {
    width: 40px;
    height: 40px;
    margin-right: 3em;
    margin-top: 0.5em;
}

.sub-container-text-neutral-agriculture {
    margin-top: 2em;
}

.center-items-verticaly {
    align-items: center;
}

.flex-align-content-start {
    align-items: flex-start;
}

.text-neutral-agriculture {
    width: 50%;
}

.center-items-horizontal {
    justify-content: center;
}

.full-height {
    height: 100%;
}

.text-color-white {
    color: white;
}

.image-container-title {
    font-size: 4vw;
    font-weight: 600;
    margin: auto;
    width: 100%;
    line-height: 1;
}


.container-text-neutral-agriculture {
    height: 70%;
    width: 50%;
    margin-left: 5em;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.image-container-subtitle {
    font-size: 1.5vw;
    margin: auto;
    margin-top: 0.5em;
    width: 100%;
}

.container-neutral-agriculture {
    height: 55vw;
    background-color: #EFF2F7;
}

.containers-button {
    background-color: #04A953;
    padding: 0.5em 3em;
    border: none;
    border-radius: 0.2em;
    font-size: 1.5vw;
}

.send-button {
    background-color: #04A953;
    padding: 0.8em 1.2em;
    border: none;
    border-radius: 0.2em;
    font-size: 20px;
    width: 35%;
}

.send-button:active {
    background-color: #288140;
}

.container-neutral-agriculture-button {
    width: 33%;
    margin-top: 2em;
}

.image-container-button-margin {
    margin-top: 3em;
}

.containers-button:active {
    background-color: #288140;
}

.image-container > .carbon-neutrality-container > .title {
    font-size: 3vw;
    margin-top: 2em;
    color: #434444;
}

.carbon-neutrality-container-subtitle-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    width: 85%;
}

.carbon-neutrality-container-subtitle-cards > .subtitle {
    font-size: 2vw;
    color: #555555;
}

.carbon-neutrality-container-cards {
    height: 25vw;
    width: 100%;
    margin-top: 4em;
    margin-bottom: 10em;
}

.clients-containe   r {
    margin-top: 10em;
    background-color:  #EFF2F7;
    height: 40vw;
}

.container-contact-us {
    margin: auto;
    width: 85%;
    margin-top: 2em;
}

.container-contact-us > .container-title-subtitle > .title {
    margin-top: 1em;
    font-size: 3vw;
    font-weight: bold;
    color: #434444;
}

.container-name-email > .container-name  {
    width: 48%;
}

#our-services > .title {
    display: flex;
    justify-content: center;
    margin: 1em 0em;
    color: #434444;
    font-size: 3vw;
    font-weight: bold;
}

.our-services-card .title {
    text-align: center;
    font-size: 2vw;
    font-weight: 600;
    color: #434444;
    margin-bottom: 0.5em;
}

.body > .description {
    height: 8em;
}

.our-services-card .description {
    font-size: 1vw;
    color: #555555;
}

.body {
    display: flex;
    flex-direction: column;
    padding: 2em;
   
}

.join-us-button {
    width: 40%;
    padding: 0.5em 2em;
    border: none;
    border-radius: 0.2em;
    font-size: 1vw;
    background-color: #04A953;
    color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.join-us-button:active {
    background-color: #288140;
}

.our-services-card {
    box-shadow: rgba(149, 157, 165, 0.13) 0px 8px 24px;
    width: 27%;
    border-radius: 0.5em;
}

.our-services {
    margin: auto;
}

.our-services-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.container-message > textarea {
    margin-top: 0.5em;
    margin-bottom: 3em;
    padding: 0.5em;
    border: 1px solid #F0F2F5;
    font-size: 16px;
}

.container-message > textarea::-webkit-input-placeholder {
    color: #C5C5C5;
}

.container-message > textarea::placeholder {
    color: #C5C5C5;
}

.container-name-email > .container-email  {
    width: 48%;
}

.container-name-email .title {    
    margin-bottom: 0.5em;
}

.container-name-email input {
    padding: 0.5em;
    margin-bottom: 2em;
    border: #F0F2F5 1px solid;
    font-size: 16px;
}

.container-name-email input::-webkit-input-placeholder {
    color: #C5C5C5;
}

.container-name-email input::placeholder {
    color: #C5C5C5;
}

.container-form-contact {
    padding: 4em 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 700px;
    height: 560px;
    border-radius: 1em;
    margin-right: 3em;
}

.container-contact-us > .container-title-subtitle > .subtitle {
    margin-top: 1em;
    font-size: 2vw;
    color: #555555;
}

.container-form-offices {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4em;
    position: relative;
}

.container-offices {
    width: 40%;
}

.container-offices span {
    width: 100%;
}

.container-form-offices > .container-offices > .title {
    color: #434444;
    font-size: 1.5vw;
}

.container-form-offices > .container-offices > .text {
    font-size: 1vw;
    color: #555555;
}

.container-form-offices > .container-offices > .margin-text {
    margin-top: 2em;
}

.clients-container {
    margin-top: 4em;
    height: 35vw;
    background-color: #EFF2F7;
}

.container-neutral-agriculture-button {
    width: 40%;
}

.clients-container > .title {
    font-size: 3vw;
    font-weight: bold;
    color: #434444;
}   

.clients-container > .subtitle {
    width: 90%;
    font-size: 2vw;
    color: #555555;
}

.logo-clients-container {
    height: 200px;
    width: 90%;
}

.slider-dot {
    background-color: #CACCD0;
    width: 10px;
    height: 10px;
    border: 3px solid #CACCD0;
    border-radius: 0.5em;
    cursor: pointer;
}

.slider-dot.active {
    background-color: #04A953 ;
    border: 3px solid #04A953;
    width: 15px;
    height: 15px;
}

.slider-dots-container {
    display: flex;
    justify-content: space-between;
    width: 5%;
}

.slider-dots-container-services  {
    display: flex;
    justify-content: space-between;
    width: 20%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.carbon-card {
    width: 30%;
    height: 25vw;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.carbon-card .title {
    font-weight: bold;
    color: #434444;
    font-size: 1.5vw;
}

.clients-container img {
    width: 15%;
    cursor: pointer;
}
.carbon-card .subtitle {
    color:#555555;
    width: 85%;
    font-size: 1vw;
    height: 50px;
}

.text-align-center {
    text-align: center;
}

.space-evenly-vertical {
    justify-content: space-evenly;
}

.co2-title {
    font-size: 3vw;
}

.co2-subtitle {
    margin-top: 1em;
    margin-bottom: 2em;
    font-size: 2vw;
    color: #434444;
}

.co2-mission {
    margin-top: 1em;
    font-size: 1.2vw;
    margin-bottom: 4em;
}

.co2-container {
    width: 80%;
    margin-left: 7.5%;
    margin-right: 4em;
}

.space-items-around {
    justify-content: space-around;
}

.co2-container > button {
    width: 40%;
    font-size: 1.5vw;
}

.image-contact {
    position: absolute;
    right: -3em;
    bottom: -5em;
}

.image-contact img {
    width: 200px;
}


.phone-input-container {
    border: #F0F2F5 1px solid;
    margin-bottom: 1em;
    padding: 0.5em;
}


.phone-container>.container-name {
    width: 48%;
    margin-top: 2em;
    margin-bottom: 2em;
}

.phone-container input {
    font-size: 16px;
}


.co2-image {
    width: 100%;
    border-radius:"0.5em";
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.next-pioneer {
    font-size: 2vw;
    color: #555555;
    margin-bottom: 1em;
}

.notification-container {
    top: 120px !important;
}
.container-subject-country .css-13cymwt-control {
    border: #F0F2F5 1px solid;
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


@media screen and (max-width: 960px) {
    .phone-container>.container-name {
        width: 100%;
    }
    .image-container {
        width: 100%;
        height: 70vw;
    }

    .about-us {
        flex-direction: column;
    }

    .about-us img {
        width: 100%;
        margin-right: unset;
    }

    .about-us .title {
        font-size: 8vw;
        text-align: center;
        margin-top: 1em;
    }

    .about-us .subtitle {
        font-size: 7vw;
    }

    .about-us .description-title {
        font-size: 4vw;
    }

    .about-us .description-subtitle {
        font-size: 4vw;
    }

    .about-us button {
        width: 50% !important;
    }

    .notification-container {
        top: 90px !important;
    }

    .next-pioneer {
        font-size: 3.5vw;
        margin-bottom: 2em;
    }

    .clients-container img {
        width: 23%;
    }

    .our-services-container {
        flex-direction: column;
        align-items: center;
    }

    .our-services-card {
        width: 80%;
        margin-bottom: 2em;
    }

    .our-services-card img {
        height: 200px;
    }

    #our-services > .title {
        font-size: 8vw;
    }

    .join-us-button {
        font-size: 4vw;
        width: 65%;
        margin: auto;
    }

    .our-services-card .title {
        font-size: 7vw;
    }

    .body > .description {
        height: 7em;
    }

    .our-services-card .description {
        font-size: 4vw;
    }

    .our-services-card {
        margin-right: 0em !important;
    }

    .container-text-neutral-agriculture .title {
        font-size: 5vw;
        margin-top: 2em;
    }

    #image-neutral-agriculture {
        margin-top: 2em;
        width: 80%;
        height: 100%;
    }

    .container-neutral-agriculture {
        flex-direction: column-reverse;
        height: unset;
    }

    .image-container-title {
        width: 70%;
        font-size: 5vw;
    }

    .co2-container > button {
        font-size: 3vw;
    }

    .co2-container {
        margin-right: unset;
    }

    .co2-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        margin-top: 2em;
    }

    #about {
        flex-direction: column;
    }

    .logo-clients-container {
        width: 98%;
        height: 50px;
    }

    .image-container-subtitle {
        font-size: 3vw;
        width: 70%;
    }

    .image-contact {
        right: 1.5em;
        bottom: -3em;
    }

    .image-contact img {
        width: 100px;
    }

    .co2-title {
        font-size: 7vw;
    }

    .container-form-offices {
        justify-content: space-around;
        margin-top: 1em;
    }

    .container-form-contact {
        margin-top: 2em;
        margin-right: unset;
        width: 100%;
        height: 111vw;
    }

    .container-name-email input {
        font-size: 3vw;
    }

    .container-message > textarea {
        font-size: 3vw;
    }

    .send-button {
        font-size: 3vw;
    }

    .container-contact-us > .container-title-subtitle > .title {
        font-size: 8vw;
    }

    .container-form-offices > .container-offices > .text {
        font-size: 3vw;
    }

    .container-contact-us > .container-title-subtitle > .subtitle {
        font-size: 3.5vw;
    }

    .container-form-offices > .container-offices > .title {
        font-size: 3vw;
    }

    .slider-dots-container {
        width: 15%;
        margin-top: 2em;
    }

    .clients-container {
        height: 70vw;
    }

    .clients-container {
        margin-top: 0em;
    }

    .clients-container .title {
        font-size: 8vw;
    }

    .clients-container .subtitle {
        font-size: 3vw;
    }

    .co2-subtitle {
        font-size: 5vw;
        width: 85%;
    }

    .carbon-neutrality-container-cards {
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-bottom: 2em;
    }

    .carbon-card {
        width: 90%;
        height: 90vw;
        margin-bottom: 2em;
    }

    .carbon-card .title {
        font-size: 5vw;
    }

    .carbon-card .subtitle {
        font-size: 4vw;
    }

    .carbon-neutrality-container-subtitle-cards > .subtitle {
        font-size: 5vw;
    }


    .image-container > .carbon-neutrality-container > .title {
        font-size: 7vw;
    }

    .containers-button {
        font-size: 3vw;
    }

    .container-text-neutral-agriculture > .title {
        font-size: 7vw;
    }

    .container-text-neutral-agriculture {
        width: 78%;
    }

    .container-text-neutral-agriculture .text {
        font-size: 4vw;
    }

    .container-text-neutral-agriculture .subtitle {
        font-size: 5vw;
    }

    .container-sustainable-solutions {
        margin-top: 6em;
    }
    .container-subject-country .css-1jqq78o-placeholder {
        font-size: 3vw;
    }
}

.container-footer {
    display: flex;
    background-color: #EFF2F7;
    height: 6vw;
    margin-top: 5em;
    align-items: center;
    justify-content: center;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.copyright {
    text-decoration: underline;
    width: 30%;
    margin-left: 1em;
}

#BV {
    width: 8vw;
    margin-right: 1em; 
}

.social-media img {
    width: 15%;
}

#Eureka {
    width: 8vw; 
    margin-right: 1em;
}

#Fpcup {
    width: 8vw; 
}

#Sealofexcellence {
    width: 6vw;
}

.container-footer > .certifications {
    width: 50%;
}

.bottom-to-screen {
    position: fixed;
    width: 100%;
    bottom: 0;
}



@media screen and (max-width: 960px) {
    .container-footer {
        height: 30vw;
        margin-top: 2em !important;
    }

    .bottom-to-screen {
        position: unset;
        bottom: unset;
    }

    .copyright {
        font-size: 3vw;
    }

    .container-footer > .social-media > img {
        width: 5vw;
    }
}


@media screen and (max-width: 1400px) {
    .container-footer {
        margin-top: 11em;
    }
}


@media screen and (max-width: 630px) {
    .container-footer {
        margin-top: 11em;
    }
}
.privacy-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 2em;
    width: 100%;
    text-align: center;
    height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    border-radius: 2em;
}

.privacy-card-container .title {
    margin-top: 1em;
    font-weight: bold;
    font-size: 32px;
}

.privacy-card-container .subtitle {
    margin-top: 1em;
    font-size: 18px;
    margin-bottom: 2em;
}

.privacy-card-button {
    width: 85%;
    padding: 0.5em 0em;
    border-radius: 0.5em;
    background-color: #04A953;
    border: none;
    color: white;
    font-size: 26px;
    position: absolute;
    bottom: -1em;
}

.modal-content {
    border-radius: 1em;
    border: none;
    width: 350px;
    height: 600px;
    margin: auto;
}

.modal {
    padding-right: none;
}

@media screen and (max-width: 960px) {
    .modal-content {
        width: 300px;
        height: 500px;
    }

}
.display-flex {
    display: flex;
}

.flex-direction-column {
    flex-direction: column;
}

.container-our-clients {
    width: 80%;
    margin: auto;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.container-our-clients .container-title {
    font-size: 2vw;
    color: #434444;
    text-align: center;
    font-weight: 600;
}

@media screen and (max-width: 960px) {
    .container-our-clients .container-title {
        font-size: 4vw;
    }
}
.display-flex {
    display: flex;
}

.flex-direction-column {
    flex-direction: column;
}

.center-items-horizontal {
    justify-content: center;
}

.space-between-horizontal {
    justify-content: space-between;
}

.center-items-vertical {
    align-items: center;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.container-our-clients {
    width: 80%;
    margin: auto;
}

.container-our-clients .conqueiros-text {
    font-size: 1.2vw;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #555555;
}

.container-stamps {
    margin: auto;
    width: 80%;
}

.container-stamp {
    width: 20%;
}

.container-stamp > .image {
    width: 80%;
}

.container-stamps > .container-stamp > .title {
    color: #555555;
    font-size: 1.5vw;
}


.container-stamps > .container-stamp > .subtitle {
    color: #555555;
    font-size: 1vw;
}

.container-stamp .check {
    width: 20%;
}

.client-logo {
    margin: auto;
    margin-top: 2em;
    height: 10em;
}

.container-stamps > .container-stamp > img {
    margin-top: 1em;
}

@media screen and (max-width: 960px) {

    .container-our-clients .conqueiros-text {
        font-size: 3vw;
    }

    .client-logo {
        height: 5em;
        margin-top: 1em;
    }

    .container-stamps {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .container-stamps > .container-stamp > .title {
        font-size: 4vw;
    }

    .container-stamps > .container-stamp > .subtitle {
        font-size: 3vw;
        text-align: center;
    }

    .container-stamp {
        width: 70%;
    }

    .container-stamp .subtitle {
        width: -webkit-min-content;
        width: min-content;
    }

    .container-stamp .check {
        width: 20%;
    }

    .container-stamp > .image {
        width: 99%;
    }
}

.popup-circle-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #04A953;
    background-image: url(/static/media/icone-form-white.60560c2a.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    position: relative;
    right: 20px;
}

.popup-container {
    position: absolute;
    right: 5px;
    bottom: 60px;
    z-index: 1;
    position: fixed;
}

@media screen and (max-width: 960px) {
    .popup-circle-container {
      width: 60px;
      height: 60px;
      background-size: 35px;
    }

    .hide-popup-circle {
        display: none;
    }
}
.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.popup-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    background-color: white;
    position: absolute;
    right: 150px;
    padding: 0.7em;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    border-radius: 1em;
    top: -600px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.inputs-icons {
    position: absolute;
    top: 23px;
    left: 20px;
    z-index: 1;
}

.form-logo {
    width: 60px;
}

.react-datepicker__input-container > input {
    border-radius: 0.5em;
    border: 2px solid #8A8A8A;
    padding: 0.5em 1em 0.5em 4em;
    margin-top: 0.5em;
    width: 73%;
    font-size: 18px;
}

.terms {
    margin-top: 4em;
    color: #8D8D90;
    font-size: 10px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #04A953 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #04A953 !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #04A953 !important;
}

.popup-form-button {
    background-color: #04A953;
    border-radius: 0.5em;
    padding: 1em;
    text-align: center;
    border: none;
    font-size: 14px;
    color: white;
    margin-top: 2em;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
}

.PhoneInputInput {
    font-size: 18px;
}

.popup-form-input {
    border-radius: 0.5em;
    border: 2px solid #8A8A8A;
    padding: 0.5em 1em 0.5em 4em;
    margin-top: 1em;
    width: 73%;
    font-size: 18px;
}

.popup-form-subtitle {
    margin-top: 1em;
    color: #8D8D90;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 1em;
}

.popup-form-title {
    font-size: 16px;
    color: #333647;
    font-weight: bold;
    margin-top: 1em;
}

.popup-form-container .css-1jqq78o-placeholder {
    font-size: 18px;
}

.container-subject-country .css-1jqq78o-placeholder {
    color: #C5C5C5;
}

.PhoneInput {
    position: relative;
    width: 300px;
    right: 41px;
}

.react-datepicker-popper {
    z-index: 2 !important;
}

.css-1nmdiq5-menu {
    z-index: 2 !important;
}

.popup-form-container .css-hlgwow {
    margin-left: 4em !important;
}

.PhoneInputInput {
    border: none;
    margin-left: 5px;
}

.popup-form-container .css-1fdsijx-ValueContainer,  .css-3w2yfm-ValueContainer{
    margin-left: 4em;
}


.popup-form-container .css-13cymwt-control {
    border: 2px solid #8A8A8A !important;
    border-radius: 0.5em !important;
}

.disable-submit-form {
    background-color: gray;
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 } 


 @media screen and (max-width: 960px) {
    .popup-form-container {
        right: -8px;
        top: -500px !important;
        z-index: -1;
        position: static;
    }
}


@media screen and (max-height: 760px) {
    .popup-circle-container {
        width: 60px;
        height: 60px;
        background-size: 40px 40px;
    }

    .popup-form-container {
        top: -440px;
    }

    .form-logo {
        width: 50px;
    }

    .popup-form-button {
        margin-top: 0.5em;
    }

    .terms {
        margin-top: 2em;
    }
    
}

