@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.display-flex {
    display: flex;
}

.flex-direction-column {
    flex-direction: column;
}

.center-items-horizontal {
    justify-content: center;
}

.space-between-horizontal {
    justify-content: space-between;
}

.center-items-vertical {
    align-items: center;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.container-our-clients {
    width: 80%;
    margin: auto;
}

.container-our-clients .conqueiros-text {
    font-size: 1.2vw;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #555555;
}

.container-stamps {
    margin: auto;
    width: 80%;
}

.container-stamp {
    width: 20%;
}

.container-stamp > .image {
    width: 80%;
}

.container-stamps > .container-stamp > .title {
    color: #555555;
    font-size: 1.5vw;
}


.container-stamps > .container-stamp > .subtitle {
    color: #555555;
    font-size: 1vw;
}

.container-stamp .check {
    width: 20%;
}

.client-logo {
    margin: auto;
    margin-top: 2em;
    height: 10em;
}

.container-stamps > .container-stamp > img {
    margin-top: 1em;
}

@media screen and (max-width: 960px) {

    .container-our-clients .conqueiros-text {
        font-size: 3vw;
    }

    .client-logo {
        height: 5em;
        margin-top: 1em;
    }

    .container-stamps {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .container-stamps > .container-stamp > .title {
        font-size: 4vw;
    }

    .container-stamps > .container-stamp > .subtitle {
        font-size: 3vw;
        text-align: center;
    }

    .container-stamp {
        width: 70%;
    }

    .container-stamp .subtitle {
        width: min-content;
    }

    .container-stamp .check {
        width: 20%;
    }

    .container-stamp > .image {
        width: 99%;
    }
}