@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.popup-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    background-color: white;
    position: absolute;
    right: 150px;
    padding: 0.7em;
    animation-name: fadeIn;
    animation-duration: 1s;
    border-radius: 1em;
    top: -600px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.inputs-icons {
    position: absolute;
    top: 23px;
    left: 20px;
    z-index: 1;
}

.form-logo {
    width: 60px;
}

.react-datepicker__input-container > input {
    border-radius: 0.5em;
    border: 2px solid #8A8A8A;
    padding: 0.5em 1em 0.5em 4em;
    margin-top: 0.5em;
    width: 73%;
    font-size: 18px;
}

.terms {
    margin-top: 4em;
    color: #8D8D90;
    font-size: 10px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #04A953 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #04A953 !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #04A953 !important;
}

.popup-form-button {
    background-color: #04A953;
    border-radius: 0.5em;
    padding: 1em;
    text-align: center;
    border: none;
    font-size: 14px;
    color: white;
    margin-top: 2em;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
}

.PhoneInputInput {
    font-size: 18px;
}

.popup-form-input {
    border-radius: 0.5em;
    border: 2px solid #8A8A8A;
    padding: 0.5em 1em 0.5em 4em;
    margin-top: 1em;
    width: 73%;
    font-size: 18px;
}

.popup-form-subtitle {
    margin-top: 1em;
    color: #8D8D90;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 1em;
}

.popup-form-title {
    font-size: 16px;
    color: #333647;
    font-weight: bold;
    margin-top: 1em;
}

.popup-form-container .css-1jqq78o-placeholder {
    font-size: 18px;
}

.container-subject-country .css-1jqq78o-placeholder {
    color: #C5C5C5;
}

.PhoneInput {
    position: relative;
    width: 300px;
    right: 41px;
}

.react-datepicker-popper {
    z-index: 2 !important;
}

.css-1nmdiq5-menu {
    z-index: 2 !important;
}

.popup-form-container .css-hlgwow {
    margin-left: 4em !important;
}

.PhoneInputInput {
    border: none;
    margin-left: 5px;
}

.popup-form-container .css-1fdsijx-ValueContainer,  .css-3w2yfm-ValueContainer{
    margin-left: 4em;
}


.popup-form-container .css-13cymwt-control {
    border: 2px solid #8A8A8A !important;
    border-radius: 0.5em !important;
}

.disable-submit-form {
    background-color: gray;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 } 


 @media screen and (max-width: 960px) {
    .popup-form-container {
        right: -8px;
        top: -500px !important;
        z-index: -1;
        position: static;
    }
}


@media screen and (max-height: 760px) {
    .popup-circle-container {
        width: 60px;
        height: 60px;
        background-size: 40px 40px;
    }

    .popup-form-container {
        top: -440px;
    }

    .form-logo {
        width: 50px;
    }

    .popup-form-button {
        margin-top: 0.5em;
    }

    .terms {
        margin-top: 2em;
    }
    
}
