@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.navbar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 120px;
    border-radius: 0em 0em 1em 1em;
    position: fixed;
    z-index: 1;
    width: 100%;
    box-shadow: 5px -1px 12px -5px grey;
}

.navbar-container > .nav-icon {
    display: none;
}

.fade-in-image { 
    animation: fadeIn 2s;
}

.navbar-container > .nav-icon-black {
    display: none;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.nav-active {
    background-color: white;
    transition: all 1s;
}

.navbar-container > .nav-logo {
    width: 15%;
    height: 100%;
    z-index: 1;
}

.navbar-container > .nav-logo > img {
    width: 100%;
    height: 100%;
}

.navbar-container > .navbar-items {
    display: flex;
    z-index: 1;
}

.navbar-container > .navbar-items > .navbar-item {
    margin: 0em 2em;
}

#contact-link {
    background-color: #04A953;
    border-radius: 0.3em;
    color: white !important;
    padding: 0.8em 2em;
}

#contact-link:active {
    background-color: #288140;
}

#contact-link:hover::before {
    width: 0%;
}

.navbar-container > .navbar-items > .navbar-item > .navbar-links {
    color: white;
    font-size: 1vw;
    text-decoration: none;
    position: relative;
    font-family: 'Roboto', sans-serif;
    padding: 0.5em 0em;
}

.navbar-container > .navbar-items > .navbar-item > .navbar-links:before {
    content: "";
    transition: all 0.5s;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0%;
    background: transparent;
}

.show-items {
    z-index: 1;
}

.hide-items {
    z-index: 0;
}

.navbar-container > .navbar-items > .navbar-item > .navbar-links:before:active {
    background-color: #288140;
}

.navbar-container > .navbar-items > .navbar-item > .navbar-links:hover:before {
    width: 100%;
    background: #04A953;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@media screen and (max-width: 960px) {

    .hide-navbar-items {
        display: none !important;
    }

    .navbar-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .background-white {
        background-color: white;
    }

    .show-navbar {
        display: flex;
    }

    .show-index {
        z-index: 1;
    }

    .hide-navbar {
        display: none;
    }

    .hide-navbar-container {
        height: 100px;
    }

    .navbar-container > .navbar-items > .navbar-item > .navbar-links {
        font-size: 16px;
        color: black !important;
    }

    .center-logo-mobile {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto; 
        margin-right: auto; 
    }


    .navbar-container > .navbar-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        opacity: 0;
        transition: all 0.5s ease;
        z-index: -1;
        margin-bottom: 20em;
    }

    .navbar-container > .nav-logo {
        width: unset;
    }

    .navbar-container > .nav-logo > img {
        width: 120px;
        margin-top: 0.5em;
        height: 50px;
    }

    .navbar-container > .navbar-items.active {
        transition: all 0.5s ease;
        opacity: 1;
    }

    .navbar-container > .navbar-items > .navbar-item {
        margin: 1em 1em;
    }

    .navbar-container > .nav-icon {
        display: block;
        position: absolute;
        top: 50px;
        left: 90%;
        color: white;
        cursor: pointer;
        z-index: 1;
    }

    .navbar-container > .nav-icon-black {
        display: block;
        position: absolute;
        top: 50px;
        left: 90%;
        color: black;
        cursor: pointer;
        z-index: 1;
    }
}