body {
  margin: 0;
  font-family: 'proxima-nova', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'proxima-nova', sans-serif;
}


.all-page {
  margin: auto;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .all-page {
    width: 100%;
  }
}