@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.display-flex {
    display: flex;
}

.flex-direction-column {
    flex-direction: column;
}

.container-our-clients {
    width: 80%;
    margin: auto;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.container-our-clients .container-title {
    font-size: 2vw;
    color: #434444;
    text-align: center;
    font-weight: 600;
}

@media screen and (max-width: 960px) {
    .container-our-clients .container-title {
        font-size: 4vw;
    }
}