.privacy-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 2em;
    width: 100%;
    text-align: center;
    height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    border-radius: 2em;
}

.privacy-card-container .title {
    margin-top: 1em;
    font-weight: bold;
    font-size: 32px;
}

.privacy-card-container .subtitle {
    margin-top: 1em;
    font-size: 18px;
    margin-bottom: 2em;
}

.privacy-card-button {
    width: 85%;
    padding: 0.5em 0em;
    border-radius: 0.5em;
    background-color: #04A953;
    border: none;
    color: white;
    font-size: 26px;
    position: absolute;
    bottom: -1em;
}

.modal-content {
    border-radius: 1em;
    border: none;
    width: 350px;
    height: 600px;
    margin: auto;
}

.modal {
    padding-right: none;
}

@media screen and (max-width: 960px) {
    .modal-content {
        width: 300px;
        height: 500px;
    }

}