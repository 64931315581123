@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
.image-container {
    background-image: url(../../Assets/Forest/image_banner.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 50vw;
    position: absolute;
    top: 0;
    z-index: 0;
}

.container-sustainable-solutions {
    width: 100%;
}

.flex-display {
    display: flex;
}

.space-items-between {
    justify-content: space-between;
}

.about-us {
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 4em;
    width: 75%;
}

.about-us img {
    width: 30vw;
    margin-right: 10em;
}

.about-us-content .title {
    font-size: 3vw;
    color: #434444;
    font-weight: bold;
}

.about-us-content .subtitle {
    font-size: 2vw;
    color: #434444;
    font-weight: bold;
    margin: 0.5em 0em;
}

.about-us-content .description-title {
    font-size: 1vw;
    color: #555555;
    margin: 1em 0em;
}

.about-us-content .description-subtitle {
    font-size: 1vw;
    color: #555555;
    margin-bottom: 3em;
}

.about-us-content {
    display: flex;
    flex-direction: column;
}

.flex-display-column {
    flex-direction: column;
}

#image-neutral-agriculture {
    width: 50%;
    height: 80%;
}

.half-width {
    width: 50%;
}

.carbon-card img {
    width: 20%;
}

.container-text-neutral-agriculture > .title {
    font-size: 3vw;
    color: #707070;
    width: 90%;
}

.container-text-neutral-agriculture .subtitle {
    font-size: 1.5vw;
    color: #555555;
    font-weight: bold;
}

.container-text-neutral-agriculture .text {
    font-size: 1vw;
    color: #555555;
    width: 80%;
}

.image-marca-sobrenos > img {
    width: 40px;
    height: 40px;
    margin-right: 3em;
    margin-top: 0.5em;
}

.sub-container-text-neutral-agriculture {
    margin-top: 2em;
}

.center-items-verticaly {
    align-items: center;
}

.flex-align-content-start {
    align-items: flex-start;
}

.text-neutral-agriculture {
    width: 50%;
}

.center-items-horizontal {
    justify-content: center;
}

.full-height {
    height: 100%;
}

.text-color-white {
    color: white;
}

.image-container-title {
    font-size: 4vw;
    font-weight: 600;
    margin: auto;
    width: 100%;
    line-height: 1;
}


.container-text-neutral-agriculture {
    height: 70%;
    width: 50%;
    margin-left: 5em;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-family-proxima {
    font-family: "Open Sans", sans-serif;
}

.image-container-subtitle {
    font-size: 1.5vw;
    margin: auto;
    margin-top: 0.5em;
    width: 100%;
}

.container-neutral-agriculture {
    height: 55vw;
    background-color: #EFF2F7;
}

.containers-button {
    background-color: #04A953;
    padding: 0.5em 3em;
    border: none;
    border-radius: 0.2em;
    font-size: 1.5vw;
}

.send-button {
    background-color: #04A953;
    padding: 0.8em 1.2em;
    border: none;
    border-radius: 0.2em;
    font-size: 20px;
    width: 35%;
}

.send-button:active {
    background-color: #288140;
}

.container-neutral-agriculture-button {
    width: 33%;
    margin-top: 2em;
}

.image-container-button-margin {
    margin-top: 3em;
}

.containers-button:active {
    background-color: #288140;
}

.image-container > .carbon-neutrality-container > .title {
    font-size: 3vw;
    margin-top: 2em;
    color: #434444;
}

.carbon-neutrality-container-subtitle-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    width: 85%;
}

.carbon-neutrality-container-subtitle-cards > .subtitle {
    font-size: 2vw;
    color: #555555;
}

.carbon-neutrality-container-cards {
    height: 25vw;
    width: 100%;
    margin-top: 4em;
    margin-bottom: 10em;
}

.clients-containe   r {
    margin-top: 10em;
    background-color:  #EFF2F7;
    height: 40vw;
}

.container-contact-us {
    margin: auto;
    width: 85%;
    margin-top: 2em;
}

.container-contact-us > .container-title-subtitle > .title {
    margin-top: 1em;
    font-size: 3vw;
    font-weight: bold;
    color: #434444;
}

.container-name-email > .container-name  {
    width: 48%;
}

#our-services > .title {
    display: flex;
    justify-content: center;
    margin: 1em 0em;
    color: #434444;
    font-size: 3vw;
    font-weight: bold;
}

.our-services-card .title {
    text-align: center;
    font-size: 2vw;
    font-weight: 600;
    color: #434444;
    margin-bottom: 0.5em;
}

.body > .description {
    height: 8em;
}

.our-services-card .description {
    font-size: 1vw;
    color: #555555;
}

.body {
    display: flex;
    flex-direction: column;
    padding: 2em;
   
}

.join-us-button {
    width: 40%;
    padding: 0.5em 2em;
    border: none;
    border-radius: 0.2em;
    font-size: 1vw;
    background-color: #04A953;
    color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.join-us-button:active {
    background-color: #288140;
}

.our-services-card {
    box-shadow: rgba(149, 157, 165, 0.13) 0px 8px 24px;
    width: 27%;
    border-radius: 0.5em;
}

.our-services {
    margin: auto;
}

.our-services-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.container-message > textarea {
    margin-top: 0.5em;
    margin-bottom: 3em;
    padding: 0.5em;
    border: 1px solid #F0F2F5;
    font-size: 16px;
}

.container-message > textarea::placeholder {
    color: #C5C5C5;
}

.container-name-email > .container-email  {
    width: 48%;
}

.container-name-email .title {    
    margin-bottom: 0.5em;
}

.container-name-email input {
    padding: 0.5em;
    margin-bottom: 2em;
    border: #F0F2F5 1px solid;
    font-size: 16px;
}

.container-name-email input::placeholder {
    color: #C5C5C5;
}

.container-form-contact {
    padding: 4em 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 700px;
    height: 560px;
    border-radius: 1em;
    margin-right: 3em;
}

.container-contact-us > .container-title-subtitle > .subtitle {
    margin-top: 1em;
    font-size: 2vw;
    color: #555555;
}

.container-form-offices {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4em;
    position: relative;
}

.container-offices {
    width: 40%;
}

.container-offices span {
    width: 100%;
}

.container-form-offices > .container-offices > .title {
    color: #434444;
    font-size: 1.5vw;
}

.container-form-offices > .container-offices > .text {
    font-size: 1vw;
    color: #555555;
}

.container-form-offices > .container-offices > .margin-text {
    margin-top: 2em;
}

.clients-container {
    margin-top: 4em;
    height: 35vw;
    background-color: #EFF2F7;
}

.container-neutral-agriculture-button {
    width: 40%;
}

.clients-container > .title {
    font-size: 3vw;
    font-weight: bold;
    color: #434444;
}   

.clients-container > .subtitle {
    width: 90%;
    font-size: 2vw;
    color: #555555;
}

.logo-clients-container {
    height: 200px;
    width: 90%;
}

.slider-dot {
    background-color: #CACCD0;
    width: 10px;
    height: 10px;
    border: 3px solid #CACCD0;
    border-radius: 0.5em;
    cursor: pointer;
}

.slider-dot.active {
    background-color: #04A953 ;
    border: 3px solid #04A953;
    width: 15px;
    height: 15px;
}

.slider-dots-container {
    display: flex;
    justify-content: space-between;
    width: 5%;
}

.slider-dots-container-services  {
    display: flex;
    justify-content: space-between;
    width: 20%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.carbon-card {
    width: 30%;
    height: 25vw;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.carbon-card .title {
    font-weight: bold;
    color: #434444;
    font-size: 1.5vw;
}

.clients-container img {
    width: 15%;
    cursor: pointer;
}
.carbon-card .subtitle {
    color:#555555;
    width: 85%;
    font-size: 1vw;
    height: 50px;
}

.text-align-center {
    text-align: center;
}

.space-evenly-vertical {
    justify-content: space-evenly;
}

.co2-title {
    font-size: 3vw;
}

.co2-subtitle {
    margin-top: 1em;
    margin-bottom: 2em;
    font-size: 2vw;
    color: #434444;
}

.co2-mission {
    margin-top: 1em;
    font-size: 1.2vw;
    margin-bottom: 4em;
}

.co2-container {
    width: 80%;
    margin-left: 7.5%;
    margin-right: 4em;
}

.space-items-around {
    justify-content: space-around;
}

.co2-container > button {
    width: 40%;
    font-size: 1.5vw;
}

.image-contact {
    position: absolute;
    right: -3em;
    bottom: -5em;
}

.image-contact img {
    width: 200px;
}


.phone-input-container {
    border: #F0F2F5 1px solid;
    margin-bottom: 1em;
    padding: 0.5em;
}


.phone-container>.container-name {
    width: 48%;
    margin-top: 2em;
    margin-bottom: 2em;
}

.phone-container input {
    font-size: 16px;
}


.co2-image {
    width: 100%;
    border-radius:"0.5em";
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.next-pioneer {
    font-size: 2vw;
    color: #555555;
    margin-bottom: 1em;
}

.notification-container {
    top: 120px !important;
}
.container-subject-country .css-13cymwt-control {
    border: #F0F2F5 1px solid;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


@media screen and (max-width: 960px) {
    .phone-container>.container-name {
        width: 100%;
    }
    .image-container {
        width: 100%;
        height: 70vw;
    }

    .about-us {
        flex-direction: column;
    }

    .about-us img {
        width: 100%;
        margin-right: unset;
    }

    .about-us .title {
        font-size: 8vw;
        text-align: center;
        margin-top: 1em;
    }

    .about-us .subtitle {
        font-size: 7vw;
    }

    .about-us .description-title {
        font-size: 4vw;
    }

    .about-us .description-subtitle {
        font-size: 4vw;
    }

    .about-us button {
        width: 50% !important;
    }

    .notification-container {
        top: 90px !important;
    }

    .next-pioneer {
        font-size: 3.5vw;
        margin-bottom: 2em;
    }

    .clients-container img {
        width: 23%;
    }

    .our-services-container {
        flex-direction: column;
        align-items: center;
    }

    .our-services-card {
        width: 80%;
        margin-bottom: 2em;
    }

    .our-services-card img {
        height: 200px;
    }

    #our-services > .title {
        font-size: 8vw;
    }

    .join-us-button {
        font-size: 4vw;
        width: 65%;
        margin: auto;
    }

    .our-services-card .title {
        font-size: 7vw;
    }

    .body > .description {
        height: 7em;
    }

    .our-services-card .description {
        font-size: 4vw;
    }

    .our-services-card {
        margin-right: 0em !important;
    }

    .container-text-neutral-agriculture .title {
        font-size: 5vw;
        margin-top: 2em;
    }

    #image-neutral-agriculture {
        margin-top: 2em;
        width: 80%;
        height: 100%;
    }

    .container-neutral-agriculture {
        flex-direction: column-reverse;
        height: unset;
    }

    .image-container-title {
        width: 70%;
        font-size: 5vw;
    }

    .co2-container > button {
        font-size: 3vw;
    }

    .co2-container {
        margin-right: unset;
    }

    .co2-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        margin-top: 2em;
    }

    #about {
        flex-direction: column;
    }

    .logo-clients-container {
        width: 98%;
        height: 50px;
    }

    .image-container-subtitle {
        font-size: 3vw;
        width: 70%;
    }

    .image-contact {
        right: 1.5em;
        bottom: -3em;
    }

    .image-contact img {
        width: 100px;
    }

    .co2-title {
        font-size: 7vw;
    }

    .container-form-offices {
        justify-content: space-around;
        margin-top: 1em;
    }

    .container-form-contact {
        margin-top: 2em;
        margin-right: unset;
        width: 100%;
        height: 111vw;
    }

    .container-name-email input {
        font-size: 3vw;
    }

    .container-message > textarea {
        font-size: 3vw;
    }

    .send-button {
        font-size: 3vw;
    }

    .container-contact-us > .container-title-subtitle > .title {
        font-size: 8vw;
    }

    .container-form-offices > .container-offices > .text {
        font-size: 3vw;
    }

    .container-contact-us > .container-title-subtitle > .subtitle {
        font-size: 3.5vw;
    }

    .container-form-offices > .container-offices > .title {
        font-size: 3vw;
    }

    .slider-dots-container {
        width: 15%;
        margin-top: 2em;
    }

    .clients-container {
        height: 70vw;
    }

    .clients-container {
        margin-top: 0em;
    }

    .clients-container .title {
        font-size: 8vw;
    }

    .clients-container .subtitle {
        font-size: 3vw;
    }

    .co2-subtitle {
        font-size: 5vw;
        width: 85%;
    }

    .carbon-neutrality-container-cards {
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-bottom: 2em;
    }

    .carbon-card {
        width: 90%;
        height: 90vw;
        margin-bottom: 2em;
    }

    .carbon-card .title {
        font-size: 5vw;
    }

    .carbon-card .subtitle {
        font-size: 4vw;
    }

    .carbon-neutrality-container-subtitle-cards > .subtitle {
        font-size: 5vw;
    }


    .image-container > .carbon-neutrality-container > .title {
        font-size: 7vw;
    }

    .containers-button {
        font-size: 3vw;
    }

    .container-text-neutral-agriculture > .title {
        font-size: 7vw;
    }

    .container-text-neutral-agriculture {
        width: 78%;
    }

    .container-text-neutral-agriculture .text {
        font-size: 4vw;
    }

    .container-text-neutral-agriculture .subtitle {
        font-size: 5vw;
    }

    .container-sustainable-solutions {
        margin-top: 6em;
    }
    .container-subject-country .css-1jqq78o-placeholder {
        font-size: 3vw;
    }
}
