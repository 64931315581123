.popup-circle-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #04A953;
    background-image: url("../../Assets/icone-form-white.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    position: relative;
    right: 20px;
}

.popup-container {
    position: absolute;
    right: 5px;
    bottom: 60px;
    z-index: 1;
    position: fixed;
}

@media screen and (max-width: 960px) {
    .popup-circle-container {
      width: 60px;
      height: 60px;
      background-size: 35px;
    }

    .hide-popup-circle {
        display: none;
    }
}