@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.container-footer {
    display: flex;
    background-color: #EFF2F7;
    height: 6vw;
    margin-top: 5em;
    align-items: center;
    justify-content: center;
}

.font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

.copyright {
    text-decoration: underline;
    width: 30%;
    margin-left: 1em;
}

#BV {
    width: 8vw;
    margin-right: 1em; 
}

.social-media img {
    width: 15%;
}

#Eureka {
    width: 8vw; 
    margin-right: 1em;
}

#Fpcup {
    width: 8vw; 
}

#Sealofexcellence {
    width: 6vw;
}

.container-footer > .certifications {
    width: 50%;
}

.bottom-to-screen {
    position: fixed;
    width: 100%;
    bottom: 0;
}



@media screen and (max-width: 960px) {
    .container-footer {
        height: 30vw;
        margin-top: 2em !important;
    }

    .bottom-to-screen {
        position: unset;
        bottom: unset;
    }

    .copyright {
        font-size: 3vw;
    }

    .container-footer > .social-media > img {
        width: 5vw;
    }
}


@media screen and (max-width: 1400px) {
    .container-footer {
        margin-top: 11em;
    }
}


@media screen and (max-width: 630px) {
    .container-footer {
        margin-top: 11em;
    }
}